import React, { useEffect, useState } from "react";
import { sendOtp } from "../../services";
import { useLocation } from "react-router";
import useLoader from "../hooks/use-loader";
import toast from "react-hot-toast";
import { MESSAGES } from "../constants/validation-constants";
import useTranslation from "../hooks/use-translation";
import { getCDSPixelId,getlanguageId } from ".";

const ResendOTPTimer = ({ onSuccess, gaClass }) => {
  const [timer, setTimer] = useState(60);
  const { state } = useLocation();
  const { toggleLoader } = useLoader();
  const { t, currentLanguage } = useTranslation();

  useEffect(() => {
    const interval = setInterval(
      () => setTimer((prev) => (prev > 0 ? prev - 1 : 0)),
      1000
    );
    return () => clearInterval(interval);
  }, []);

  const resendOtp = async () => {
    // Handle form submission
    if (state && state.mobile) {
      toggleLoader(true);
      const hfSessionKey = await getCDSPixelId();
      try {
        const reqData = {
          mobileNo: state && String(state.mobile),
          HfSession: hfSessionKey || "",
          languageId: getlanguageId(currentLanguage),
        };
        const res = await sendOtp(reqData);
        if (res.status === 200) {
          setTimer(60);
          onSuccess();
        }
      } catch (err) {
      } finally {
        toggleLoader(false);
      }
    } else {
      toast.error(MESSAGES.CANT_FETCH_MOBILE);
    }
  };

  return (
    <div>
      {timer ? (
        <p style={{ fontSize: "1.2rem" }}>
          {t.auth.try_again_timer} {`00:${timer < 10 ? "0" : ""}${timer}`}
        </p>
      ) : (
        <span
          style={{ fontSize: "1.3rem" }}
          className={`text-dark btn-underline ${gaClass}`}
          id={gaClass}
          onClick={() => {
            resendOtp();
          }}
        >
          {t.auth.resend_code}
        </span>
      )}
    </div>
  );
};

export default ResendOTPTimer;
