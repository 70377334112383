import React, { useState } from "react";
import AppModal from "../../modals";
import useLoader from "../../../utils/hooks/use-loader";
import useTranslation from "../../../utils/hooks/use-translation";
import { addEditUserQuestion } from "../../../services";
import { getlanguageId } from "../../../utils/helpers";
import useAuth from "../../../utils/hooks/use-auth";
import { useDispatch } from "react-redux";
import { getUserDetails } from "../../../utils/axios-helper/api-hander";
import { getWalletList } from "../../../utils/axios-helper/api-hander";
import { setCoinAnimation } from "../../../store/slices/common-slice";

const ProgressiveModal = (props) => {
  const { t, currentLanguage } = useTranslation();
  const { show, onClose, params } = props;
  const { toggleLoader } = useLoader();
  const { userDetails } = useAuth();
  const dispatch = useDispatch();

  const [selectedOptionId, setSelectedOptionId] = useState(null);

  const handleOptionChange = (optionId) => {
    setSelectedOptionId(optionId);
  };

  const handleSubmit = async (event) => {
    toggleLoader(true);
    try {
      const langId = getlanguageId(currentLanguage);
      const reqData = {
        langId: langId,
        eventID: params.eventID,
        optionId: event === "close" ? 0 : selectedOptionId || 0,
        questionId: params.questionId,
      };
      const res = await addEditUserQuestion(reqData);
      if (res.data.succeeded && event === "submit") {
        dispatch(setCoinAnimation(true));
        setTimeout(() => {
          dispatch(setCoinAnimation(false));
        }, 2500);
      }
      getUserDetails(userDetails, dispatch, toggleLoader);
      getWalletList(toggleLoader, dispatch);
    } catch (err) {
      console.log(err);
    } finally {
      toggleLoader(false);
      onClose();
    }
  };

  const { questionText, options } = params;

  return (
    <>
      {show ? (
        <AppModal
          show={show}
          size="md"
          onClose={() => handleSubmit("close")}
          bodyClass="modal-response modal-unique-code text-center"
        >
          <div className="row justify-content-center">
            <div className="col-sm-8">
              <h2
                style={{
                  fontSize: "20px",
                  textAlign: "left",
                  marginBottom: "20px",
                }}
              >
                {questionText}
              </h2>
              <div className="options-container" style={{ textAlign: "left" }}>
                {params.eventID === "5" ? (
                  <select
                    onChange={(e) => handleOptionChange(e.target.value)}
                    style={{
                      width: "100%",
                      padding: "10px",
                      marginBottom: "15px",
                    }}
                  >
                    <option value="">Select an option</option>
                    {options &&
                      options.map((el) => (
                        <option key={el.optionId} value={el.optionId}>
                          {el.optionText}
                        </option>
                      ))}
                  </select>
                ) : (
                  options &&
                  options.map((el) => (
                    <div
                      key={el.optionId}
                      style={{
                        marginBottom: "15px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="radio"
                        id={`option-${el.optionId}`}
                        name="selectedOption"
                        value={el.optionId}
                        checked={selectedOptionId === el.optionId}
                        onChange={() => handleOptionChange(el.optionId)}
                        style={{
                          marginRight: "10px",
                          accentColor:
                            selectedOptionId === el.optionId ? "black" : "",
                        }}
                      />
                      <label
                        htmlFor={`option-${el.optionId}`}
                        style={{ fontSize: "14px", textAlign: "left" }}
                      >
                        {el.optionText}
                      </label>
                    </div>
                  ))
                )}
              </div>
              <div style={{ textAlign: "center", marginTop: "30px" }}>
                <button
                  style={{
                    backgroundColor: selectedOptionId ? "black" : "#ccc",
                    color: "white",
                    padding: "10px 25px",
                    fontSize: "15px",
                    border: "none",
                    cursor: selectedOptionId ? "pointer" : "not-allowed",
                    borderRadius: "35px",
                    opacity: selectedOptionId ? 1 : 0.5,
                    transition: "opacity 0.3s ease",
                  }}
                  disabled={!selectedOptionId}
                  onClick={() => handleSubmit("submit")}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </AppModal>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default ProgressiveModal;
