import React from "react";
import AppModal from "./index";
import DOMPurify from "dompurify";

const ExitModal = ({ extraContent, show, onClose, image, heading, subHeading, cta, timer,
    description, timerContent, onAction, timerValue, cancel, confirm }) => {

    return (
        <AppModal
            show={show}
            onClose={onClose}
            bodyClass=" modal-response"
            size="md"

        >
            <div className="text-center">
                {image && <img src={image} alt="Icon" className='modal-icon mb-md-4 mb-2' />}
                {heading && <h6 className='modal-heading pb-md-3 pb-1'>{heading}</h6>}
                {subHeading &&
                    <p className='modal-description pb-md-2 pb-1'
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(subHeading),
                        }}
                    ></p>}
                {extraContent}

                {description && <p className='modal-notification'>{description}</p>}
                {confirm && <button className='btn btn-dark mb-2 mr-5' onClick={onAction}>{confirm}</button>}
                {cancel && <button className='btn btn-dark mb-2' onClick={onClose}>{cancel}</button>}
            </div>
        </AppModal>
    )
}

export default ExitModal