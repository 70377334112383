// ViewportContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';

const ViewportContext = createContext();

export const ViewportProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 992);
  };

  useEffect(() => {
    // Call the function initially
    handleResize();

    // Add a listener for window resize events
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on context provider unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // The empty dependency array ensures that the effect runs only once on mount

  return (
    <ViewportContext.Provider value={{ isMobile }}>
      {children}
    </ViewportContext.Provider>
  );
};

export const useViewport = () => {
  const context = useContext(ViewportContext);
  if (!context) {
    throw new Error('useViewport must be used within a ViewportProvider');
  }
  return context;
};
