import { useContext } from "react";
import { PopupContext } from "../../components/common/context/popup-context";

function usePopup() {
  const { openPopup, setProgressive } = useContext(PopupContext);
  const openUniqueCodePopup = (action, params) =>
    openPopup("unique_code", action, params);
  const openProgressivePopup = (params) => {
    setProgressive({ show: true, data: params });
  };
  const openReferPopup = (action) => openPopup("refer", action);
  const openReferFriendPopup = (action) => openPopup("refer_friend", action);
  const openStartQuizPopup = (action, params) =>
    openPopup("start_quiz", action, params);
  const openSuccessPopup = (action, params) =>
    openPopup("success_model", action, params);
  const openLogoutPopup = (action, params) =>
    openPopup("logout_model", action, params);
  const openContactUsPopup = (action) => openPopup("contact_us", action);
  return {
    openUniqueCodePopup,
    openProgressivePopup,
    openReferPopup,
    openReferFriendPopup,
    openStartQuizPopup,
    openSuccessPopup,
    openLogoutPopup,
    openContactUsPopup,
  };
}

export default usePopup;
