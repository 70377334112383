import { combineReducers } from "redux";

import auth from "./auth-slice";
import common from "./common-slice";
import karaoke from "./karaokeRecord-slice";

export default combineReducers({
  auth,
  common,
  karaoke
});
