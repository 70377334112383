import React from 'react';
import AppModal from './index';
import { Link } from 'react-router-dom';
import useTranslation from '../../utils/hooks/use-translation';

const ContactUs = ({ show, onClose }) => {
  const { t } = useTranslation();
  return (
    <AppModal
      show={show}
      onClose={onClose}
      bodyClass="h-auto pt-0 mt-n3"
      dialogClassName="modal-video-wrapper"
      contentClassName={'text-center contact'}>
      <h3 className="contact-title">{t.header.contact_us}</h3>
      <ul className="contact-list">
        <li className="contact-list-item">
          <img
            className="contact-list-icon"
            src={'/assets/img/icons/email.svg'}
            alt="icon"
          />
          <h4 className="contact-list-title">{t.header.email}</h4>
          <Link
            to={'mailto:indiahelpline@coca-cola.com'}
            target="_blank"
            className="contact-list-link">
            indiahelpline@coca-cola.com
          </Link>
        </li>
        <li className="contact-list-item">
          <img
            className="contact-list-icon"
            src={'/assets/img/icons/phone.svg'}
            alt="icon"
          />
          <h4 className="contact-list-title">{t.header.toll_Free}</h4>
          <Link
            to={'tel:1800 208 2653'}
            target="_blank"
            className="contact-list-link">
            1800 208 2653
          </Link>
          <p className="mb-5 mb-md-6">
            9:00am-9:00pm (Mon-Sat) <br />
            9:00am-6:00pm (Sun)
          </p>
        </li>
      </ul>
    </AppModal>
  );
};

export default ContactUs;
