import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import rootReducer from "./slices";

// Configure the persistence for the root reducer
const persistConfig = {
  key: "root", // Key for the persisted state in the storage
  version: 1, // Version of the persisted state (used for migrations)
  storage, // Storage engine to be used (in this case, `storage` imported from `redux-persist/lib/storage`)
};

// Create a persisted reducer using the root reducer and persist configuration
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the Redux store using `configureStore`
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(thunk),
  devTools: process.env.REACT_APP_ENV !== "production",
});

// Create the persistor using `persistStore` and the configured store
export const persistor = persistStore(store);
