import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux/es/hooks/useSelector";
import useAuth from "../../../utils/hooks/use-auth";
import useTranslation from "../../../utils/hooks/use-translation";

function ProfileImageSelector({ selected = "", setFieldValue }) {
  const { t } = useTranslation();
  const { userDetails } = useAuth();
  const avatarList = useSelector((state) => state?.common?.avatarList);
  const avatarDataList = Object.values(avatarList);
  const [open, setOpen] = useState(false);
  const [selectedOption] = useState({});

  // Assuming isDisplayEditIcon is defined somewhere in your component
  const isDisplayEditIcon = true;

  useEffect(() => {
    if (selected) {
      setOpen(false);
    }
  }, [selected]);

  const handleProfileSelected = (name, value, type, avatarObj) => {
    if (name && value) {
      const tempImg = new Image();
      tempImg.src = value;
      tempImg.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = 51;
        canvas.height = 51;
        ctx.drawImage(tempImg, 0, 0, 53, 53);

        if (type === "icon") {
          setFieldValue(name, value);
          setFieldValue("profileId", avatarObj.id);
        } else {
          const resizedImage = canvas.toDataURL("image/jpeg");
          setFieldValue(name, resizedImage);
        }
      };
    }
  };

  return (
    <>
      <Dropdown show={open} onToggle={setOpen} drop="down-centered">
        <Dropdown.Toggle
          as="span"
          className="avatar-wrapper dropdown-arrow-disable px-0"
        >
          <img
            className="avatar-img"
            src={
              selected
                ? selected
                : userDetails && userDetails.avatarURL
                ? userDetails.avatarURL
                : "/assets/img/icons/default-user.svg"
            }
            alt="add-icon"
          />

          {isDisplayEditIcon && (
            <img
              className="avatar-edit"
              src="/assets/img/icons/edit.svg"
              alt="add-icon"
            />
          )}
        </Dropdown.Toggle>

        {/* edit profile options list  */}
        <Dropdown.Menu renderOnMount={true}>
          <h4 className="text-center w-100 mb-2">{t.common.choose_avatar}</h4>
          <div>
            {avatarDataList.map((a) => (
              <img
                className="avatar-img"
                src={a.imageURL}
                alt="avatar"
                onClick={() =>
                  handleProfileSelected("profileImage", a.imageURL, "icon", a)
                }
                key={a.id}
              />
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
      {selectedOption === "chooseAvatar" && !selected && !open && (
        <div className="position-absolute avatar-select-wrapper">
          {avatarDataList.map((a) => (
            <img
              className="avatar-img"
              src={a.imageURL}
              alt="avatar"
              onClick={() =>
                handleProfileSelected("profileImage", a.imageURL, "icon", a)
              }
              key={a.id}
            />
          ))}
        </div>
      )}
    </>
  );
}

export default ProfileImageSelector;
