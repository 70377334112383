import { AxiosInstance } from '../utils/axios-helper/axios-helper';
import { API_END_POINT } from '../utils/constants/api-end-points';
import { version } from '../config';

export const getContestData = async (data) => {
  return await AxiosInstance.get(
    `${API_END_POINT.getContest}?languageId=${data.languageId}`
  );
};

export const getTamilQuizDetails = async () => {
  return await AxiosInstance.get(`${version}${API_END_POINT.getQuiz}`);
};

export const postQuizAnswerAPI = async (data) => {
  return await AxiosInstance.post(`${version}${API_END_POINT.postQuizAnswer}`, data);
};



