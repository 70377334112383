
import React, { useState, useEffect, useRef } from 'react';

const CountdownTimer = ({ title,initialTime }) => {
  const initialTimeObject = {
    hours:initialTime ?  parseInt(initialTime.split(':')[0], 10) : 0,
    minutes: initialTime ?  parseInt(initialTime.split(':')[1], 10) : 0,
    seconds:initialTime ?  parseInt(initialTime.split(':')[2], 10) : 0,
  };

  const [time, setTime] = useState(initialTimeObject);
  const intervalRef = useRef(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTime((prevTime) => {
        const newTime = { ...prevTime };
        if (newTime.seconds > 0) {
          newTime.seconds--;
        } else {
          if (newTime.minutes > 0) {
            newTime.minutes--;
            newTime.seconds = 59;
          } else {
            if (newTime.hours > 0) {
              newTime.hours--;
              newTime.minutes = 59;
              newTime.seconds = 59;
            } else {
              clearInterval(intervalRef.current);
            }
          }
        }
        return newTime;
      });
    }, 1000);

    return () => clearInterval(intervalRef.current);
  }, []); // Empty dependency array means this useEffect runs only once on component mount

  return (
    <p className="card-time-stamp mb-0">
      {`${title}:`}{' '}
      <span className="text-danger font-weight-bold">
        {`${time.hours.toString().padStart(2, '0')}:${time.minutes
          .toString()
          .padStart(2, '0')}:${time.seconds.toString().padStart(2, '0')}`}
      </span>
    </p>
  );
};

export default CountdownTimer;

