import en from '../lang/en.json'; // English
import hi from '../lang/hi.json'; //Hindi
import ta from '../lang/ta.json'; // Tamil

const translations = {
  en,
  hi,
  ta,
};

export default translations;
