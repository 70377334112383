import { AxiosInstance } from "../utils/axios-helper/axios-helper";
import { API_END_POINT } from "../utils/constants/api-end-points";
import { version } from '../config'


// get karaoke song list 
export const getkaraokeSongList = async (data) => {
  return await AxiosInstance.get(`${API_END_POINT.karaokeSongList}?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`);
};


//upload karaoke song audio or video
export const uploadkaraoke = async (data) => {
  return await AxiosInstance.post(`${API_END_POINT.uploadUserkaraoke}`, data);
  //return await AxiosInstance.post(`https://d0b0-103-15-67-130.ngrok-free.app/api/Karaoke/AddEditUserKaraoke`, data);
};

export const uploadKaraokeFile = async (data) => {
 return await AxiosInstance.post(`${API_END_POINT.uploadFilekaraoke}`, data);
  //return await AxiosInstance.post(`https://d0b0-103-15-67-130.ngrok-free.app/api/Karaoke/AddUserFile`, data);
}

export const uploadKaroakeLarge = async (data) => {
  return await AxiosInstance.post(`${API_END_POINT.uploadKaroakeLarge}`, data);
   //return await AxiosInstance.post(`https://d0b0-103-15-67-130.ngrok-free.app/api/Karaoke/AddUserFile`, data);
 }

export const earnKaraokeCokeCoins = async (data) =>  {
  return await AxiosInstance.post(`${version}${API_END_POINT.earnkaraokePoints}`, data);
}




